import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../sectionTitle/SectionTitle';
import ModalWindow from "../../common/ModalWindow";
import FormGudok from "./FormGudok";


function FormSale({ props, formStyle }) {

    const { t, i18n } = useTranslation();

    const [modalActive, setModalActive] = useState(false);

    // Открытие модального окна заказа
    const OpenForm = () => {
        setModalActive(true);
    }

    // Закрытие модального окна
    const CloseForm = () => {
        setModalActive(false);
    }

    return (

        <div className="fl-sale bg-color-blackest radius mt--60">
            <div className="row position-relative align-items-center flex-column flex-lg-row">


                <div className="col-xl-8 col-lg-7 col-12 order-lg-1 order-2">
                    <div className="fl-sale__content">
                        <SectionTitle
                            textAlign="text-left"
                            radiusRounded=""
                            subtitle={t("services.sale.subtitle")}
                            title={t("services.sale.title")}
                            description={t("services.sale.decription")}
                        />

                        <div><button className="btn-default btn-large" onClick={OpenForm}>{t("services.sale.button")}</button></div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 order-lg-2 order-1">
                    <div className="fl-sale__image">
                        <img src="./images/contact/sale_1.png" />
                    </div>
                </div>
            </div>

            {/* Отображение модального окна заказа */}
            <ModalWindow active={modalActive} closeCallback={CloseForm}>
                <FormGudok title={t("contacts.formSale.title")} subtitle={t("contacts.formSale.subtitle")} />
            </ModalWindow>
        </div>


    )
}
export default FormSale;
