import PropTypes from "prop-types";
import React from 'react'

import { useTranslation } from 'react-i18next';


const SEO = ({ title, description }) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} || {t("seo.mainTitle")}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

export default SEO;


