import React from 'react';

const Loader = ({ data }) => {

    return (
        <>
            <div className='loader'>
                <div className="loader__container">
                    <div className="loader__img">
                        <img src="./images/loading-min.gif" alt="" />
                    </div>
                    <div className="loader__title">Loading...</div>
                    <div className="loader__subtitle">Пожалуйста, подождите немного</div>
                </div>
            </div>
        </>
    )
}
export default Loader;
