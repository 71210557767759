import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from "react-router-dom";
import TimelineTwo from '../timeline/TimelineTwo';
import ServiceFour from "../service/ServiceFour";

const dataList = [
    {
        icon: '1',
        title: 'Сбор данных',
        description: [
            'Продающий лендинг',
            'Многостраничный сайт',
            'Интернет магазин',
        ],
    },
    {
        icon: '2',
        title: 'Разработка ТЗ',
        description: [
            'Продающий лендинг',
            'Многостраничный сайт',
            'Интернет магазин',
        ],
    },
    {
        icon: '3',
        title: 'Разработка сайта',
        description: [
            'Продающий лендинг',
            'Многостраничный сайт',
            'Интернет магазин',
        ],
    },
    {
        icon: '4',
        title: 'Тестирование',
        description: [
            'Продающий лендинг',
            'Многостраничный сайт',
            'Интернет магазин',
        ],
    },
    {
        icon: '5',
        title: 'Доработка сайта',
        description: [
            'Продающий лендинг',
            'Многостраничный сайт',
            'Интернет магазин',
        ],
    },
    {
        icon: '6',
        title: 'Передача прав доступа',
        description: [
            'Продающий лендинг',
            'Многостраничный сайт',
            'Интернет магазин',
        ],
    },
]

const dataList2 = [
    {
        icon: '1',
        title: 'Awarded Design',
        description: 'There are many variations variations have.'
    },
    {
        icon: '2',
        title: 'Design & Creative',
        description: 'Passages there are many variations have.'
    },
    {
        icon: '3',
        title: 'App Development',
        description: 'Variations There variations many Lorem Ipsum.'
    },
    {
        icon: '4',
        title: 'Product Designer',
        description: 'Variations There variations of Lorem Ipsum.'
    },
    {
        icon: '5',
        title: 'Product Designer',
        description: 'Variations There variations of Lorem Ipsum.'
    },
    {
        icon: '6',
        title: 'Product Designer',
        description: 'Variations There variations of Lorem Ipsum.'
    },
]

const TabThree = () => {
    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <Tabs>
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-12">
                                <div className="rn-default-tab style-three">
                                    <div className="tab-button-panel">
                                        <TabList className="tab-button">
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>Разработка</button>
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>Маркетинг</button>
                                                </div>
                                            </Tab>
                                        </TabList>
                                    </div>

                                    <div className="tab-content-panel">
                                        <TabPanel>
                                            {/* <div className="rn-tab-content">
                                                <div className="inner">
                                                    <div className="row align-items-center row--30">
                                                        <div className="col-lg-4">
                                                            <div className="section-title">
                                                                <h4 className="title">How can we help you?</h4>
                                                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero ea sint nihil corrupti! Harum nemo eius odio.</p>
                                                                <div className="read-more">
                                                                    <a className="btn-default btn-small" href="#">Read More</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 mt_md--30 mt_sm--30">
                                                            <div className="row row--15 mt_dec--30 service-wrapper">
                                                                {dataList.map((val, i) => (
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                                                        <div className="card-box card-style-1 text-start">
                                                                            <div className="inner">
                                                                                <div className="image">
                                                                                    <Link to="#service">
                                                                                        <img src={`${val.image}`} alt="card Images" />
                                                                                    </Link>
                                                                                </div>
                                                                                <div className="content">
                                                                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{ __html: val.title }}></Link></h4>
                                                                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                                                                                    <Link className="btn-default btn-small" to="#service">Read More</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <ServiceFour
                                                serviceStyle="service__style--1 icon-circle-style with-working-process"
                                                textAlign="text-center"
                                                data={dataList}
                                            />
                                        </TabPanel>

                                        <TabPanel>
                                            {/* <div className="radius rn-tab-content">
                                                <div className="inner">
                                                    <div className="row align-items-center row--30">
                                                        <div className="col-lg-4">
                                                            <div className="section-title">
                                                                <h4 className="title">Whice Service Provided?</h4>
                                                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero ea sint nihil corrupti! Harum nemo eius odio.</p>
                                                                <div className="read-more">
                                                                    <a className="btn-default btn-small" href="#">Read More</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8 mt_md--30 mt_sm--30">
                                                            <div className="row row--15 mt_dec--30 service-wrapper">
                                                                {dataList2.map((val, i) => (
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                                                        <div className="card-box card-style-1 text-start">
                                                                            <div className="inner">
                                                                                <div className="image">
                                                                                    <Link to="#service">
                                                                                        <img src={`${val.image}`} alt="card Images" />
                                                                                    </Link>
                                                                                </div>
                                                                                <div className="content">
                                                                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{ __html: val.title }}></Link></h4>
                                                                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                                                                                    <Link className="btn-default btn-small" to="#service">Read More</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <ServiceFour
                                                serviceStyle="service__style--1 icon-circle-style with-working-process"
                                                textAlign="text-center"
                                                data={dataList}
                                            />
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default TabThree
