import React, { useRef, useState } from "react";
import {
    FileUploadContainer,
    FormField,
    DragDropText,
    UploadFileBtn,
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,
    RemoveFileIcon,
    InputLabel
} from "./fileUploadStyles";

import { useTranslation } from 'react-i18next';

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10485760;

const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label,
    updateFilesCb,
    showPreview,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    // Смена языка
    const { t, i18n } = useTranslation();
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = (newFiles) => {
        for (let file of newFiles) {
            if (file.size < maxFileSizeInBytes) {
                if (!otherProps.multiple) {
                    return { file };
                }
                files[file.name] = file;
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = (fileName) => {
        delete files[fileName];
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
    };

    return (
        <>
            <FileUploadContainer>
                <InputLabel>{label}</InputLabel>
                <DragDropText>{t("contacts.form.tz")}</DragDropText>
                <div className="file-upload__subtitle">{t("contacts.form.tz_subtitle")}</div>
                <div className="file-upload__subtitle"><b>{t("contacts.form.tz_resolution")}</b> *.docx, *.pdf</div>
                {/* <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                    <span> Прекрипите {otherProps.multiple ? "файлы" : "файл"}</span>
                </UploadFileBtn> */}
                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    name="file"
                    {...otherProps}
                />
            </FileUploadContainer>
            {showPreview &&
                <FilePreviewContainer>
                    <PreviewList>
                        {Object.keys(files).map((fileName, index) => {
                            let file = files[fileName];
                            let isImageFile = file.type.split("/")[0] === "image";
                            return (
                                <PreviewContainer key={fileName}>
                                    <div className="file-upload__preview">
                                        {isImageFile && (
                                            <ImagePreview
                                                src={URL.createObjectURL(file)}
                                                alt={`file preview ${index}`}
                                            />
                                        )}
                                        <FileMetaData isImageFile={isImageFile}>
                                            <span>{file.name}</span>
                                            <aside>
                                                <span>{convertBytesToKB(file.size)} кб</span>
                                                <RemoveFileIcon
                                                    className="fas fa-trash-alt"
                                                    onClick={() => removeFile(fileName)}
                                                />
                                            </aside>
                                        </FileMetaData>
                                    </div>
                                </PreviewContainer>
                            );
                        })}
                    </PreviewList>
                </FilePreviewContainer>
            }

        </>
    );
};

export default FileUpload;