import React from 'react';
import { useTranslation } from 'react-i18next';

import PortfolioDetailsContent from "../elements/portfolio/PortfolioDetailsContent";
import PortfolioData from "../data/portfolio/PortfolioData.json";
import PortfolioDataEn from "../data/portfolio/PortfolioDataEn.json";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import PortfolioMarketing from '../elements/portfolio/PortfolioMarketing';


const PortfolioDetails = ({ match: { params: { id } } }) => {
    const { t, i18n } = useTranslation();

    const portfolioId = parseInt(id, 10)

    let data;
    let CategoryOne;
    let CategoryTwo;

    if (i18n.language === "ru") {
        data = PortfolioData.filter(portfolio => portfolio.id === portfolioId);
        CategoryOne = "разработка"
        CategoryTwo = "маркетинг"
    }

    if (i18n.language === "en") {
        data = PortfolioDataEn.filter(portfolio => portfolio.id === portfolioId);
        CategoryOne = "development"
        CategoryTwo = "marketing"
    }


    const category = data[0].category;
    return (
        <>
            <SEO title={data[0].title + " || FLAMINGO"} />
            <Layout>
                {category === CategoryOne &&
                    <PortfolioDetailsContent data={data[0]} />
                }
                {category === CategoryTwo &&
                    <PortfolioMarketing data={data[0]} />
                }
            </Layout>
        </>
    )
}

export default PortfolioDetails;
