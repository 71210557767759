import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SectionTitle from '../sectionTitle/SectionTitle';
import ModalWindow from "../../common/ModalWindow";
import FormGudok from "./FormGudok";

function FormAudit({ props, formStyle }) {

    const { t, i18n } = useTranslation();

    const [modalActive, setModalActive] = useState(false);

    // Открытие модального окна заказа
    const OpenForm = () => {
        setModalActive(true);
    }

    // Закрытие модального окна
    const CloseForm = () => {
        setModalActive(false);
    }


    return (
        <div className="fl-audit bg-color-blackest radius">
            <div className="row position-relative align-items-center">

                <div className="col-lg-6 col-12 order-2 order-lg-1">
                    <SectionTitle
                        textAlign="text-left"
                        radiusRounded=""
                        subtitle={t("audit.subtitle")}
                        title={t("audit.title")}
                        description={t("audit.description")}
                    />

                    <div className="read-more-btn mt--30">
                        <button className="btn-default btn-large" onClick={OpenForm}>{t("audit.button")}</button>
                    </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-12 order-1 order-lg-2">
                    <div className="fl-audit__image">
                        <img src="./images/contact/back_01.png" />
                    </div>
                </div>

                <div className="col-3 d-none d-xl-block order-lg-3">
                    <div className="fl-audit__content">
                        <div>
                            <p>{t("audit.add.addBlockOne.subtitle")}</p>
                            <div>{t("audit.add.addBlockOne.title")}</div>
                        </div>
                        <div>
                            <p>{t("audit.add.addBlockTwo.subtitle")}</p>
                            <div>{t("audit.add.addBlockTwo.title")}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Отображение модального окна заказа */}
            <ModalWindow active={modalActive} closeCallback={CloseForm}>
                <FormGudok title={t("contacts.formAudit.title")} subtitle={t("contacts.formAudit.subtitle")} />
            </ModalWindow>
        </div>

    )
}
export default FormAudit;
