import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Nav = ({ closeMenuAfterLink }) => {

    const { t, i18n } = useTranslation();

    return (
        <ul className="mainmenu">
            <li><Link to={process.env.PUBLIC_URL + "/#services"} onClick={closeMenuAfterLink}>{t("header.menu.services")}</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/#cases"} onClick={closeMenuAfterLink}>{t("header.menu.cases")}</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/#about"} onClick={closeMenuAfterLink}>{t("header.menu.about")}</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/#clients"} onClick={closeMenuAfterLink}>{t("header.menu.clients")}</Link></li>
            <li><Link to={process.env.PUBLIC_URL + "/#contacts"} onClick={closeMenuAfterLink}>{t("header.menu.contacts")}</Link></li>
        </ul>
    )
}
export default Nav;
