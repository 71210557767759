import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const TimelineTwo = ({ classVar, data }) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            {data.map((el, i) => (
                <div key={i} className="row row--0 mt--30">
                    {/* Start Single Progress */}
                    {el.map((data, index) => (
                        <div className={`col-lg-4 col-md-4 rn-timeline-single ${classVar}`} key={index}>
                            <div className="rn-timeline">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <h6 className="title">{data.icon + '. ' + data.title}</h6>
                                </ScrollAnimation>
                                <div className="progress-line">
                                    <div className="line-inner"></div>
                                </div>
                                <div className="progress-dot">
                                    <div className="dot-level">
                                        <div className="dot-inner"></div>
                                    </div>
                                </div>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">{data.deadline}</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    ))}

                    {/* End Single Progress */}
                </div>
            ))}
        </div>
    )
}
export default TimelineTwo;