import React, { useState } from 'react';
import { Link } from "react-router-dom";

function MessengersPanel() {

    const [activeGudok, setActiveGudok] = useState(false);

    // Функция имитации клика по объекту
    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    function simulateMouseClick(element) {
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
    }

    const openGudok = () => {
        const ButtonGudok = document.querySelector('#cbw-buttonButton');
        const GudokContainer = document.querySelector('#cbw-popupContainer');

        simulateMouseClick(ButtonGudok);

        if (GudokContainer == null) {
            setActiveGudok(true);
        }

        if (GudokContainer.classList.contains('cbw-show')) {
            setActiveGudok(true);
        } else {
            setActiveGudok(false);
        }
    }

    return (
        <div className="messenger-panel">
            <div className={"messenger-panel__callback" + `${activeGudok ? ' active' : ''}`} onClick={openGudok}></div>
            <div className="messenger-panel__whatsapp"><Link to={{ pathname: "https://api.whatsapp.com/send?phone=79282962128" }} target="_blank"></Link></div>
            <div className="messenger-panel__telegramm"><Link to={{ pathname: "https://t.me/Flamingo_expert" }} target="_blank"></Link></div>
        </div>
    )
}
export default MessengersPanel;
