import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { useTranslation } from 'react-i18next';


const ServiceOne = ({ textAlign, serviceStyle }) => {

    const { t, i18n } = useTranslation();


    const ServiceList = [
        {
            icon: `${process.env.PUBLIC_URL}/images/icons/software-developer.png`,
            title: t("services.services.itemOne.title"),
            description: [
                t("services.services.itemOne.decription.1"),
                t("services.services.itemOne.decription.2"),
                t("services.services.itemOne.decription.3"),
                t("services.services.itemOne.decription.4")
            ],
            price: t("services.services.itemOne.price")
        },
        {
            icon: `${process.env.PUBLIC_URL}/images/icons/strategy.png`,
            title: t("services.services.itemTwo.title"),
            description: [
                t("services.services.itemTwo.decription.1"),
                t("services.services.itemTwo.decription.2"),
                t("services.services.itemTwo.decription.3"),
                t("services.services.itemTwo.decription.4")
            ],
            price: t("services.services.itemTwo.price")
        },
        {
            icon: `${process.env.PUBLIC_URL}/images/icons/analytics_1.png`,
            title: t("services.services.itemThree.title"),
            description: [
                t("services.services.itemThree.decription.1"),
                t("services.services.itemThree.decription.2"),
                t("services.services.itemThree.decription.3")
            ],
            price: t("services.services.itemThree.price")
        },
    ]

    return (
        <div className="row row--15 service-wrapper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-12 mb-5 mb-lg-0" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div>
                                <div className="icon">
                                    <img src={val.icon} alt="" />
                                </div>
                                <div className="content">
                                    <h4 className="title w-600">{val.title}</h4>
                                    <ul>
                                        {val.description.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>

                                </div>
                            </div>
                            <div className="price">{val.price}</div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;