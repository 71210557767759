import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// Компоненты
import TabPortfolio from '../tab/TabPortfolio';
import TechologyPortfolio from './TechologyPortfolio';
import CmsPortfolio from './CmsPortfolio';
import { useState } from 'react';
import { useEffect } from 'react';

import { FiArrowLeft } from "react-icons/fi";

const PortfolioDetailsContent = ({ data }) => {

    const { t, i18n } = useTranslation();

    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        const width = window.screen.width;
        if (width < 480) {
            setMobile(true);
        }
    }, [])

    return (
        <div className="rwt-portfolio-details rn-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="portfolio-details">
                                <div className="portfolio-details__header">
                                    <div className="portfolio-details__name">
                                        <h2>{data.title}</h2>
                                    </div>
                                    <Link className="btn-default btn-medium btn-border btn-icon" to={process.env.PUBLIC_URL + "/#cases"}><i className="icon"><FiArrowLeft /></i> {t("cases.back")}</Link>
                                </div>

                                <div className="portfolio-details__main">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="portfolio-details__client bg-color-blackest radius">
                                                <div className="portfolio-details__client-main">
                                                    <div>
                                                        <div className="portfolio-details__title">{t("cases.caseDevelop.сlient")}</div>
                                                        <div className="portfolio-details__client-title">{data.client}</div>
                                                    </div>
                                                    <div>
                                                        <img src={data.logo} alt="" />
                                                    </div>
                                                </div>
                                                <div>
                                                    {data.aboutClient.map((el, i) => (
                                                        <p key={i}>{el}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="portfolio-details__target">
                                                <div className="portfolio-details__title">{t("cases.caseDevelop.target")}</div>
                                                <div className="portfolio-details__target-text">{data.typeDevelopment}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-details__tasks">
                                    <div className="portfolio-details__title">{t("cases.caseDevelop.objectives")}</div>
                                    <div className="portfolio-details__tasks-block">
                                        <div className="row">
                                            {data.tasks.map((task, index) => (
                                                <div key={index} className="col-xl-4 col-md-6 col-12">
                                                    <div className="portfolio-details__task">
                                                        <div className="portfolio-details__task-number">{index + 1}</div>
                                                        <div className="portfolio-details__task-text">{task}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-details__solution">
                                    <div className="portfolio-details__title">{t("cases.caseDevelop.solution")}</div>
                                    <div className="portfolio-details__development">
                                        {data.solution.map((block, i) => (
                                            <div key={i} className="portfolio-details__development-block">
                                                <div className="portfolio-details__subtitle">{block.nameBlock}</div>
                                                {block.TwoColumn ?
                                                    <div className="row align-items-center">
                                                        <div className="col-xl-8 col-lg-7 col-12">
                                                            {block.text.map((paragraph, index) => (
                                                                <p key={index}>{paragraph}</p>
                                                            ))}
                                                        </div>
                                                        <div className="col-xl-4 col-lg-5 col-12">
                                                            {block.SecondColumnContent === 'techology' &&
                                                                <TechologyPortfolio data={data.techology} />
                                                            }

                                                            {block.SecondColumnContent === 'cms' &&
                                                                <CmsPortfolio data={data.cms} />
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    block.text.map((paragraph, index) => (
                                                        <div key={index} className="row">
                                                            <div className="col-12">
                                                                <p>{paragraph}</p>
                                                            </div>
                                                        </div>

                                                    ))
                                                }

                                                {block.addText &&
                                                    block.addText.map((paragraph, index) => (
                                                        <p key={index}>{paragraph}</p>
                                                    ))
                                                }

                                                {block.tabsOn && mobile && block.TabsOnMobile &&
                                                    <TabPortfolio data={block.MobileTabs} addClass="portfolio-details_tabs-mobile" />
                                                }

                                                {block.tabsOn && mobile && !block.TabsOnMobile &&
                                                    <TabPortfolio data={block.dataTabs} />
                                                }

                                                {block.tabsOn && !mobile &&
                                                    <TabPortfolio data={block.dataTabs} />
                                                }


                                            </div>
                                        ))}
                                    </div>
                                    <div className='mt-5 text-center'><Link className="btn-default btn-medium btn-border btn-icon" to={process.env.PUBLIC_URL + "/#cases"}><i className="icon"><FiArrowLeft /></i> {t("cases.back")}</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioDetailsContent;
