import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

const CookieBar = () => {

    const cookies = new Cookies();

    const [infoCookies, setInfoCookies] = useState(false);

    useEffect(() => {
        if (cookies.get('cookies_resolution')) {
            setInfoCookies(false)
        } else {
            setInfoCookies(true)
        }
    }, [])

    const setCookies = () => {
        cookies.set('cookies_resolution', 'yes', [{ path: '/' }, { expires: 2592000 }]);
        setInfoCookies(false);
    }

    return (
        <>
            {infoCookies &&
                <div className='cookie-bar'>
                    <div className="cookie-bar__container">
                        <div className="cookie-bar__text">
                            Мы собираем куки. Без них никак. <Link to={`${process.env.PUBLIC_URL + "/policy"}`}>Подробнее...</Link>
                        </div>
                        <div className="cookie-bar__button">
                            <button onClick={setCookies}>OK</button>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}
export default CookieBar;
