import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

// Иконки
import { SiVk } from "react-icons/si";
import { ImTelegram, ImWhatsapp } from "react-icons/im";
import { FiMapPin, FiPhone } from "react-icons/fi";
import Dzen from "../../assets/images/icons/dzen.png"
import Tenchat from "../../assets/images/icons/tenchat.png"

const HeaderTopBar = () => {

    const { t, i18n } = useTranslation();

    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">

                    <div className="col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p><FiMapPin /><span>{t("contacts.adressRussia")}</span></p>
                                <p><FiPhone /><span><a href="tel:+78632962128">8 (863) 296-21-28</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><Link to={{ pathname: "https://api.whatsapp.com/send?phone=79282962128" }} target="_blank"><ImWhatsapp /></Link></li>
                                    <li><Link to={{ pathname: "https://t.me/Flamingo_expert" }} target="_blank"><ImTelegram /></Link></li>
                                    <li><Link to={{ pathname: "https://vk.com/flamingo.expert" }} target="_blank"><SiVk /></Link></li>
                                    <li><Link to={{ pathname: "https://dzen.ru/id/6478a2b3d1ad8e779af33fdc" }} target="_blank"><img src={Dzen} /></Link></li>
                                    <li><Link to={{ pathname: "https://tenchat.ru/1211925" }} target="_blank"><img src={Tenchat} /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
