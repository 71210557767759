import React from 'react';
import { useTranslation } from 'react-i18next';

// Компоненты основные
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree.jsx';

const Policy = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <SEO title="Политика конфиденциальности || FLAMINGO" />
            <main className="page-wrapper">

                {/* Шапка */}
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Блок контактов */}
                <div className="policy-area rn-section-gap" >
                    <div className="container">
                        <div className="row row--30 mt--40">

                            {i18n.language === "ru" &&
                                <div className="col-12">

                                    <h2>Политика конфиденциальности</h2>
                                    <p>Политика в отношении обработки персональных данных</p>

                                    <h3>1. Общие положения</h3>
                                    <p>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые ИП Наумов Денис Анатольевич (далее — Оператор).</p>
                                    <p>1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>
                                    <p>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://flamingo.expert/.</p>

                                    <h3>2. Основные понятия, используемые в Политике</h3>
                                    <p>2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.</p>
                                    <p>2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
                                    <p>2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://flamingo.expert/.</p>
                                    <p>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>
                                    <p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.</p>
                                    <p>2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                                    <p>2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                                    <p>2.8. Персональные данные — любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://flamingo.expert/.</p>
                                    <p>2.9. Персональные данные, разрешенные субъектом персональных данных для распространения, — персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном Законом о персональных данных (далее — персональные данные, разрешенные для распространения).</p>
                                    <p>2.10. Пользователь — любой посетитель веб-сайта https://flamingo.expert/.</p>
                                    <p>2.11. Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
                                    <p>2.12. Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом.</p>
                                    <p>2.13. Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу.</p>
                                    <p>2.14. Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и/или уничтожаются материальные носители персональных данных.</p>

                                    <h3>3. Основные права и обязанности Оператора</h3>
                                    <p>3.1. Оператор имеет право:
                                        <br />— получать от субъекта персональных данных достоверные информацию и/или документы, содержащие персональные данные;
                                        <br />— в случае отзыва субъектом персональных данных согласия на обработку персональных данных, а также, направления обращения с требованием о прекращении обработки персональных данных, Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в Законе о персональных данных;
                                        <br />— самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными законами.
                                    </p>
                                    <p>3.2. Оператор обязан:
                                        <br />— предоставлять субъекту персональных данных по его просьбе информацию, касающуюся обработки его персональных данных;
                                        <br />— организовывать обработку персональных данных в порядке, установленном действующим законодательством РФ;
                                        <br />— отвечать на обращения и запросы субъектов персональных данных и их законных представителей в соответствии с требованиями Закона о персональных данных;
                                        <br />— сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в течение 10 дней с даты получения такого запроса;
                                        <br />— публиковать или иным образом обеспечивать неограниченный доступ к настоящей Политике в отношении обработки персональных данных;
                                        <br />— принимать правовые, организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;
                                        <br />— прекратить передачу (распространение, предоставление, доступ) персональных данных, прекратить обработку и уничтожить персональные данные в порядке и случаях, предусмотренных Законом о персональных данных;
                                        <br />— исполнять иные обязанности, предусмотренные Законом о персональных данных.
                                    </p>

                                    <h3>4. Основные права и обязанности субъектов персональных данных</h3>
                                    <p>4.1. Субъекты персональных данных имеют право:
                                        <br />— получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных. Перечень информации и порядок ее получения установлен Законом о персональных данных;
                                        <br />— требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав;
                                        <br />— выдвигать условие предварительного согласия при обработке персональных данных в целях продвижения на рынке товаров, работ и услуг;
                                        <br />— на отзыв согласия на обработку персональных данных, а также, на направление требования о прекращении обработки персональных данных;
                                        <br />— обжаловать в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке неправомерные действия или бездействие Оператора при обработке его персональных данных;
                                        <br />— на осуществление иных прав, предусмотренных законодательством РФ.
                                    </p>
                                    <p>
                                        4.2. Субъекты персональных данных обязаны:
                                        <br />— предоставлять Оператору достоверные данные о себе;
                                        <br />— сообщать Оператору об уточнении (обновлении, изменении) своих персональных данных.
                                    </p>
                                    <p>4.3. Лица, передавшие Оператору недостоверные сведения о себе, либо сведения о другом субъекте персональных данных без согласия последнего, несут ответственность в соответствии с законодательством РФ.</p>

                                    <h3>5. Принципы обработки персональных данных</h3>
                                    <p>5.1. Обработка персональных данных осуществляется на законной и справедливой основе.</p>
                                    <p>5.2. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей. Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
                                    <p>5.3. Не допускается объединение баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.</p>
                                    <p>5.4. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
                                    <p>5.5. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых персональных данных по отношению к заявленным целям их обработки.</p>
                                    <p>5.6. При обработке персональных данных обеспечивается точность персональных данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки персональных данных. Оператор принимает необходимые меры и/или обеспечивает их принятие по удалению или уточнению неполных или неточных данных.</p>
                                    <p>5.7. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</p>

                                    <h3>6. Цели обработки персональных данных</h3>
                                    <p>6.1 Цель обработки: предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте
                                        <br />— Персональные данные: фамилия, имя, отчество, электронный адрес, номера телефонов
                                        <br />— Правовые основания: Федеральный закон «Об информации, информационных технологиях и о защите информации» от 27.07.2006 N 149-ФЗ
                                        <br />— Виды обработки персональных данных: Сбор, запись, систематизация, накопление, хранение, уничтожение и обезличивание персональных данных
                                        <br />— Отправка информационных писем на адрес электронной почты
                                    </p>
                                    <p>6.2 Цель обработки: информирование Пользователя посредством отправки электронных писем
                                        <br />— Персональные данные: фамилия, имя, отчество,электронный адрес,номера телефонов
                                        <br />— Правовые основания: Федеральный закон «Об информации, информационных технологиях и о защите информации» от 27.07.2006 N 149-ФЗ
                                        <br />— Виды обработки персональных данных: Сбор, запись, систематизация, накопление, хранение, уничтожение и обезличивание персональных данных
                                        <br />— Отправка информационных писем на адрес электронной почты
                                    </p>

                                    <h3>7. Условия обработки персональных данных</h3>
                                    <p>7.1. Обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных.</p>
                                    <p>7.2. Обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей.</p>
                                    <p>7.3. Обработка персональных данных необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве.</p>
                                    <p>7.4. Обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем.</p>
                                    <p>7.5. Обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных.</p>
                                    <p>7.6. Осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе (далее — общедоступные персональные данные).</p>
                                    <p>7.7. Осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.</p>

                                    <h3>8. Порядок сбора, хранения, передачи и других видов обработки персональных данных</h3>
                                    <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
                                    <p>8.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
                                    <p>8.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства либо в случае, если субъектом персональных данных дано согласие Оператору на передачу данных третьему лицу для исполнения обязательств по гражданско-правовому договору.</p>
                                    <p>8.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора info@flamingo.expert с пометкой «Актуализация персональных данных».</p>
                                    <p>8.4. Срок обработки персональных данных определяется достижением целей, для которых были собраны персональные данные, если иной срок не предусмотрен договором или действующим законодательством.
                                        Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора info@flamingo.expert с пометкой «Отзыв согласия на обработку персональных данных».</p>
                                    <p>8.5. Вся информация, которая собирается сторонними сервисами, в том числе платежными системами, средствами связи и другими поставщиками услуг, хранится и обрабатывается указанными лицами (Операторами) в соответствии с их Пользовательским соглашением и Политикой конфиденциальности. Субъект персональных данных и/или с указанными документами. Оператор не несет ответственность за действия третьих лиц, в том числе указанных в настоящем пункте поставщиков услуг.</p>
                                    <p>8.6. Установленные субъектом персональных данных запреты на передачу (кроме предоставления доступа), а также на обработку или условия обработки (кроме получения доступа) персональных данных, разрешенных для распространения, не действуют в случаях обработки персональных данных в государственных, общественных и иных публичных интересах, определенных законодательством РФ.</p>
                                    <p>8.7. Оператор при обработке персональных данных обеспечивает конфиденциальность персональных данных.</p>
                                    <p>8.8. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.</p>
                                    <p>8.9. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия согласия субъекта персональных данных, отзыв согласия субъектом персональных данных или требование о прекращении обработки персональных данных, а также выявление неправомерной обработки персональных данных.</p>

                                    <h3>9. Перечень действий, производимых Оператором с полученными персональными данными</h3>
                                    <p>9.1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение персональных данных.</p>
                                    <p>9.2. Оператор осуществляет автоматизированную обработку персональных данных с получением и/или передачей полученной информации по информационно-телекоммуникационным сетям или без таковой.</p>

                                    <h3>10. Трансграничная передача персональных данных</h3>
                                    <p>10.1. Оператор до начала осуществления деятельности по трансграничной передаче персональных данных обязан уведомить уполномоченный орган по защите прав субъектов персональных данных о своем намерении осуществлять трансграничную передачу персональных данных (такое уведомление направляется отдельно от уведомления о намерении осуществлять обработку персональных данных).</p>
                                    <p>10.2. Оператор до подачи вышеуказанного уведомления, обязан получить от органов власти иностранного государства, иностранных физических лиц, иностранных юридических лиц, которым планируется трансграничная передача персональных данных, соответствующие сведения.</p>

                                    <h3>11. Конфиденциальность персональных данных</h3>
                                    <p>Оператор и иные лица, получившие доступ к персональным данным, обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.</p>

                                    <h3>12. Заключительные положения</h3>
                                    <p>12.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты info@flamingo.expert.</p>
                                    <p>12.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
                                    <p>12.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://flamingo.expert/policy.</p>


                                </div>
                            }
                            {i18n.language === "en" &&
                                <div className="col-12">
                                    <h2>Privacy Policy</h2>
                                    <p>Policy regarding the processing of personal data</p>

                                    <h3>1. General provisions</h3>
                                    <p>The present policy of personal data processing is drawn up in accordance with the requirements of the Federal Law dated 27.07.2006. No. 152-FZ "On Personal Data" (hereinafter - the Law on Personal Data) and determines the order of personal data processing and measures to ensure personal data security. to ensure the security of personal data taken by IE Naumov Denis Anatolievich (hereinafter - the Operator).</p>
                                    <p>1.1 The Operator sets as its most important goal and condition of its activities the observance of human and civil rights and freedoms in the processing of personal data, including the protection of the right to privacy, personal and family secrecy.</p>
                                    <p>1.2. This Operator's policy on personal data processing (hereinafter - the Policy) applies to all information that the Operator may obtain about visitors to the website https://flamingo.expert/.</p>

                                    <h3>2. Basic concepts used in the Policy</h3>
                                    <p>2.1 Automated processing of personal data - processing of personal data by means of computer equipment.</p>
                                    <p>2.2. Blocking of personal data - temporary termination of processing of personal data (except for cases when processing is necessary to clarify personal data).</p>
                                    <p>2.3. Website - a set of graphic and informational materials, as well as computer programs and databases, ensuring their availability on the Internet at the network address https://flamingo.expert/.</p>
                                    <p>2.4. Personal data information system - an aggregate of personal data contained in databases and information technologies and technical means ensuring their processing.</p>
                                    <p>2.5. Personal data depersonalization - actions as a result of which it is impossible to determine, without the use of additional information, whether personal data belong to a particular User or other subject of personal data.</p>
                                    <p>2.6 Processing of personal data means any action (operation) or set of actions (operations) performed with or without the use of automated means with personal data, including collection, recording, systematization, accumulation, storage, clarification (update, change), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data.</p>
                                    <p>2.7 Operator's Guide to Personal Data - any action (operation) or set of actions (operations) performed with or without the use of automated means with personal data.</p>
                                    <p>2.7. Operator - a state authority, municipal authority, legal entity or individual, independently or jointly with other persons, organizing and/or carrying out the processing of personal data, as well as determining the purposes of personal data processing, the composition of personal data subject to processing, actions (operations) performed with personal data.</p>
                                    <p>2.8. Personal Data - any information relating directly or indirectly to a specific or identifiable User of https://flamingo.expert/.</p>
                                    <p>2.9. Personal data authorized by the subject of personal data for dissemination - personal data to which the subject of personal data has granted access to an unlimited number of persons by giving consent to the processing of personal data authorized by the subject of personal data for dissemination in the manner prescribed by the Personal Data Law (hereinafter - personal data authorized for dissemination).</p>
                                    <p>2.10. Personal data - personal data authorized for dissemination.</p>
                                    <p>2.10. User - any visitor of the website https://flamingo.expert/.</p>
                                    <p>2.11. Provision of personal data - any actions aimed at disclosure of personal data to a certain person or a certain circle of persons.</p>
                                    <p>2.12. Dissemination of personal data - any actions aimed at disclosure of personal data to an indefinite number of persons (transfer of personal data) or familiarization of personal data to an unlimited number of persons, including disclosure of personal data in mass media, placement in information and telecommunication networks or providing access to personal data in any other way.</p>
                                    <p>2.13. Transborder transfer of personal data means transfer of personal data to the territory of a foreign country to a foreign government authority, a foreign individual or a foreign legal entity.</p>
                                    <p>2.14. Destruction of personal data - any actions as a result of which personal data are irretrievably destroyed with the impossibility of further recovery of the content of personal data in the information system of personal data and/or material carriers of personal data are destroyed.</p>

                                    <h3>3. Basic rights and obligations of the Operator</h3>

                                    <p>3.1 The Operator has the right to:
                                        <br />- to receive from the subject of personal data reliable information and/or documents containing personal data;
                                        <br />- In case the personal data subject revokes his/her consent to the processing of personal data, as well as if he/she submits a request to stop processing of personal data, the Operator has the right to continue processing personal data without the consent of the personal data subject if there are grounds specified in the Personal Data Law;
                                        <br />- independently determine the composition and list of measures necessary and sufficient to ensure the fulfillment of obligations under the Personal Data Law and regulatory legal acts adopted in accordance with it, unless otherwise provided for by the Personal Data Law or other federal laws.
                                    </p>
                                    <p>3.2 The Operator shall:
                                        <br />- provide the subject of personal data at his/her request with information regarding the processing of his/her personal data;
                                        <br />- organize the processing of personal data in accordance with the procedure established by the applicable laws of the Russian Federation;
                                        <br />- respond to appeals and requests of personal data subjects and their legal representatives in accordance with the requirements of the Law on Personal Data;
                                        <br />- report to the authorized body for the protection of the rights of personal data subjects, upon request of this body, the necessary information within 10 days from the date of receipt of such request;
                                        <br />- to publish or otherwise provide unrestricted access to this Policy on personal data processing;
                                        <br />- take legal, organizational and technical measures to protect personal data from unlawful or accidental access to them, destruction, modification, blocking, copying, provision, dissemination of personal data, as well as from other unlawful actions with regard to personal data;
                                        <br />- cease transfer (dissemination, provision, access) of personal data, stop processing and destroy personal data in the manner and cases stipulated by the Personal Data Law;
                                        <br />- fulfill other obligations stipulated by the Personal Data Law.
                                    </p>
                                    <h3>4. Basic rights and obligations of personal data subjects</h3>
                                    <p>4.1 Personal data subjects have the right to:
                                        <br />- to receive information regarding the processing of his/her personal data, except in cases provided for by federal laws. Information shall be provided to the subject of personal data by the Operator in an accessible form and shall not contain personal data relating to other subjects of personal data, except in cases where there are legal grounds for disclosure of such personal data. The list of information and the procedure for obtaining it is established by the Law on Personal Data;
                                        <br />- demand from the operator to clarify his personal data, block or destroy it in case the personal data is incomplete, outdated, inaccurate, illegally obtained or not necessary for the stated purpose of processing, as well as to take measures provided by law to protect his rights;
                                        <br />- to impose the condition of prior consent when processing personal data in order to market goods, works and services;
                                        <br />- to withdraw consent to the processing of personal data, as well as to submit a request to stop the processing of personal data;
                                        <br />- to appeal to the authorized body for the protection of the rights of personal data subjects or in court against unlawful acts or omissions of the Operator in the processing of his/her personal data;
                                        <br />- to exercise other rights provided for by the legislation of the Russian Federation.
                                    </p>
                                    <p>
                                        4.2 The subjects of personal data shall be obliged to:
                                        <br />- provide the Operator with reliable data about themselves;
                                        <br />- notify the Operator of the clarification (update, change) of their personal data.
                                    </p>
                                    <p>4.3 Persons who have passed to the Operator false information about themselves, or information about another subject of personal data without the consent of the latter, shall be liable in accordance with the legislation of the Russian Federation.</p>

                                    <h3>5. Principles of personal data processing</h3>
                                    <p>5.1 Processing of personal data shall be carried out on a lawful and fair basis.</p>
                                    <p>5.2 The processing of personal data shall be limited to the achievement of specific, predetermined and legitimate purposes. Processing of personal data incompatible with the purposes of personal data collection is not allowed.</p>
                                    <p>5.3 Databases containing personal data processed for incompatible purposes may not be merged.</p>
                                    <p>5.4. Only personal data that meet the purposes of their processing shall be processed.</p>
                                    <p>5.5 The content and scope of personal data processed corresponds to the stated purposes of processing. The personal data processed may not be redundant in relation to the stated purposes of processing.</p>
                                    <p>5.6 When processing personal data, the accuracy of personal data, their sufficiency and, where necessary, relevance in relation to the purposes of personal data processing shall be ensured. The Operator shall take the necessary measures and/or ensure that they are taken to delete or clarify incomplete or inaccurate data.</p>
                                    <p>5.7 Storage of personal data is carried out in a form that allows to identify the subject of personal data, not longer than required by the purposes of personal data processing, unless the period of storage of personal data is established by federal law, contract to which the subject of personal data is a party, beneficiary or guarantor. Processed personal data shall be destroyed or depersonalized upon achievement of the purposes of processing or in case of loss of necessity to achieve these purposes, unless otherwise provided for by federal law.</p>

                                    <h3>6. Purposes of personal data processing</h3>
                                    <p>6.1 Purpose of processing: to provide the User with access to services, information and/or materials contained on the website
                                        <br />- Personal data: surname, first name, patronymic, e-mail address, telephone numbers
                                        <br />- Legal basis: Federal Law "On Information, Information Technologies and Information Protection" dated 27.07.2006 N 149-FZ.
                                        <br />- Types of personal data processing: Collection, recording, systematization, accumulation, storage, destruction and depersonalization of personal data
                                        <br />- Sending informational letters to the e-mail address
                                    </p>
                                    <p>6.2 Purpose of processing: informing the User by sending e-mails.
                                        <br />- Personal data: surname, first name, patronymic, e-mail address, telephone numbers.
                                        <br />- Legal basis: Federal Law "On Information, Information Technologies and Information Protection" dated 27.07.2006 N 149-FZ.
                                        <br />- Types of personal data processing: Collection, recording, systematization, accumulation, storage, destruction and depersonalization of personal data
                                        <br />- Sending informational letters to the e-mail address
                                    </p>

                                    <h3>7. Conditions of personal data processing</h3>
                                    <p>7.1 Processing of personal data shall be carried out with the consent of the subject of personal data to the processing of his/her personal data.</p>
                                    <p>7.2. Processing of personal data is necessary to achieve the purposes provided for by an international treaty of the Russian Federation or by law, to fulfill the functions, powers and duties assigned to the operator by the legislation of the Russian Federation.</p>
                                    <p>7.3. Processing of personal data is necessary for the administration of justice, execution of a judicial act, act of another authority or official subject to execution in accordance with the legislation of the Russian Federation on enforcement proceedings.</p>
                                    <p>7.4 The processing of personal data is necessary for the execution of an agreement to which the personal data subject is a party, beneficiary or guarantor, as well as for the conclusion of an agreement at the initiative of the personal data subject or an agreement under which the personal data subject will be a beneficiary or guarantor.</p>
                                    <p>7.5 The processing of personal data is necessary for the exercise of the rights and legitimate interests of the operator or third parties or for the achievement of socially important goals, provided that the rights and freedoms of the personal data subject are not violated.</p>
                                    <p>7.6. Personal data is processed if it is accessible to an unlimited number of persons by the subject of personal data or at his/her request (hereinafter - publicly available personal data).</p>
                                    <p>7.7. Personal data subject to publication or mandatory disclosure in accordance with federal law is processed.</p>


                                    <h3>8. Procedure for collection, storage, transfer and other types of personal data processing</h3>
                                    <p>The security of personal data processed by the Operator shall be ensured by implementing legal, organizational and technical measures necessary for full compliance with the requirements of applicable legislation in the field of personal data protection.</p>
                                    <p>8.1 The Operator shall ensure the safety of personal data and take all possible measures to prevent unauthorized persons from accessing personal data.</p>
                                    <p>8.2 User's personal data will never, under no circumstances, be transferred to third parties, except in cases related to the execution of applicable law or if the subject of personal data has given consent to the Operator to transfer the data to a third party for the fulfillment of obligations under a civil law contract.</p>
                                    <p>8.3 If any inaccuracies in personal data are detected, the User may update them independently by sending the Operator a notice to the Operator's e-mail address info@flamingo.expert marked "Personal Data Update".</p>
                                    <p>8.4 The period of personal data processing is determined by the achievement of the purposes for which the personal data was collected, unless another period is stipulated by the contract or applicable law.
                                        The User may withdraw his/her consent to the processing of personal data at any time by sending a notice to the Operator by e-mail to the Operator's e-mail address info@flamingo.expert with the note "Withdrawal of consent to the processing of personal data".</p>
                                    <p>8.5. All information about the User's consent to the processing of personal data shall be sent to the Operator by e-mail to the Operator's e-mail address info@flamingo.expert.</p>
                                    <p>8.5 All information that is collected by third-party services, including payment systems, means of communication and other service providers, is stored and processed by these persons (Operators) in accordance with their User Agreement and Privacy Policy. The subject of personal data and/or with the said documents. The Operator is not responsible for the actions of third parties, including the service providers specified in this paragraph.</p>
                                    <p>8.6. The prohibitions established by the subject of personal data on the transfer (except for granting access), as well as on the processing or conditions of processing (except for obtaining access) of personal data authorized for dissemination shall not apply in cases of processing personal data in the state, public and other public interests defined by the legislation of the Russian Federation.</p>
                                    <p>8.7. When processing personal data, the Operator shall ensure confidentiality of personal data.</p>
                                    <p>8.8 The Operator shall store personal data in a form that allows to identify the subject of personal data for no longer than required for the purposes of personal data processing, unless the period of personal data storage is established by federal law, an agreement to which the subject of personal data is a party, beneficiary or guarantor.</p>
                                    <p>8.9. The termination of personal data processing may be conditioned by the achievement of the purposes of personal data processing, expiration of the consent of the subject of personal data, withdrawal of consent by the subject of personal data or a request to terminate personal data processing, as well as detection of unlawful processing of personal data.</p>

                                    <h3>9. List of actions performed by the Operator with the received personal data</h3>
                                    <p>9.1 The Operator shall collect, record, systematize, accumulate, store, clarify (update, change), extract, use, transfer (disseminate, provide, access), depersonalize, block, delete and destroy personal data.</p>
                                    <p>9.2. The operator carries out automated processing of personal data with or without receiving and/or transmitting the received information via information and telecommunication networks.</p>

                                    <h3>10. Trans-border transfer of personal data</h3>
                                    <p>10.1 Before commencing transborder transfer of personal data, the Operator shall notify the authorized body for the protection of the rights of personal data subjects of its intention to carry out transborder transfer of personal data (such notification shall be sent separately from the notification on the intention to carry out personal data processing).</p>
                                    <p>10.2. Before submitting the above notification, the Operator is obliged to obtain relevant information from the authorities of a foreign state, foreign individuals, foreign legal entities to whom the transborder transfer of personal data is planned.</p>

                                    <h3>11. Confidentiality of personal data</h3>
                                    <p>The operator and other persons who have access to personal data are obliged not to disclose to third parties and not to disseminate personal data without the consent of the subject of personal data, unless otherwise provided for by federal law.</p>

                                    <h3>12. Final provisions</h3>
                                    <p>12.1. The User may obtain any clarifications on matters of interest regarding the processing of his/her personal data by contacting the Operator via e-mail at info@flamingo.expert.</p>
                                    <p>12.2 This document will reflect any changes to the Operator's personal data processing policy. The Policy is valid indefinitely until it is replaced by a new version.</p>
                                    <p>12.3 The current version of the Policy is freely available on the Internet at https://flamingo.expert/policy.</p>
                                </div>
                            }

                        </div>
                    </div>
                </div>


                {/* Футер */}
                <FooterThree />

            </main>
        </>
    )
}

export default Policy;
