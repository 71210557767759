import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TimelineTwo from "../timeline/TimelineTwo";
import Steps from '../timeline/Steps';

const TabTwo = () => {

    const { t, i18n } = useTranslation();

    const dataListOne = {
        steps: [
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/pie-chart.png`,
                title: t("steps.tabs.tabOne.body.1.title"),
                deadline: t("steps.tabs.tabOne.body.1.deadline"),
                length: 'w-1',
                position: '',
                mobile: '1'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/edit-text.png`,
                title: t("steps.tabs.tabOne.body.2.title"),
                deadline: t("steps.tabs.tabOne.body.2.deadline"),
                length: 'w-1',
                position: 'fl-steps_body-1',
                mobile: '2'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/coding.png`,
                title: t("steps.tabs.tabOne.body.3.title"),
                deadline: t("steps.tabs.tabOne.body.3.deadline"),
                length: 'w-2',
                position: 'fl-steps_body-2',
                mobile: '3-4'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/nut.png`,
                title: t("steps.tabs.tabOne.body.4.title"),
                deadline: t("steps.tabs.tabOne.body.4.deadline"),
                length: 'w-05',
                position: 'fl-steps_body-4',
                mobile: '5'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/rotate.png`,
                title: t("steps.tabs.tabOne.body.5.title"),
                deadline: t("steps.tabs.tabOne.body.5.deadline"),
                length: 'w-1',
                position: 'fl-steps_body-45',
                mobile: '5'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/key.png`,
                title: t("steps.tabs.tabOne.body.6.title"),
                deadline: t("steps.tabs.tabOne.body.6.deadline"),
                length: 'w-0',
                position: 'fl-steps_body-6',
                mobile: '5-6'
            },

        ],
        weeks: 6
    }

    const dataListTwo = {
        steps: [
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/pie-chart.png`,
                title: t("steps.tabs.tabTwo.body.1.title"),
                deadline: t("steps.tabs.tabTwo.body.1.deadline"),
                length: 'w-1',
                position: '',
                mobile: '1'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/pyramid.png`,
                title: t("steps.tabs.tabTwo.body.2.title"),
                deadline: t("steps.tabs.tabTwo.body.2.deadline"),
                length: 'w-15',
                position: 'fl-steps_body-05',
                mobile: '1-2'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/strategy_1.png`,
                title: t("steps.tabs.tabTwo.body.3.title"),
                deadline: t("steps.tabs.tabTwo.body.3.deadline"),
                length: 'w-1',
                position: 'fl-steps_body-2',
                mobile: '3'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/nut.png`,
                title: t("steps.tabs.tabTwo.body.4.title"),
                deadline: t("steps.tabs.tabTwo.body.4.deadline"),
                length: 'w-4',
                position: 'fl-steps_body-3',
                mobile: '4-7'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/space-ship.png`,
                title: t("steps.tabs.tabTwo.body.5.title"),
                deadline: t("steps.tabs.tabTwo.body.5.deadline"),
                length: 'w-1',
                position: 'fl-steps_body-3',
                mobile: '4'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/magnifying-glass.png`,
                title: t("steps.tabs.tabTwo.body.6.title"),
                deadline: t("steps.tabs.tabTwo.body.6.deadline"),
                length: 'w-1',
                position: 'fl-steps_body-5',
                mobile: '6'
            },
            {
                icon: `${process.env.PUBLIC_URL}/images/icons/edit-text.png`,
                title: t("steps.tabs.tabTwo.body.7.title"),
                deadline: t("steps.tabs.tabTwo.body.7.deadline"),
                length: 'w-4',
                position: 'fl-steps_body-4',
                mobile: '5-8'
            },

        ],
        weeks: 8
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <Tabs>
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-12 mt_md--40 mt_sm--40">
                                <div className="rn-default-tab style-two">
                                    <div className="tab-button-panel">
                                        <TabList className="tab-button">
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>{t("steps.tabs.tabOne.name")}</button>
                                                </div>
                                            </Tab>
                                            <Tab>
                                                <div className="rn-tab-button">
                                                    <button>{t("steps.tabs.tabTwo.name")}</button>
                                                </div>
                                            </Tab>
                                        </TabList>
                                    </div>

                                    <div className="tab-content-panel">
                                        <TabPanel>
                                            <Steps data={dataListOne} />
                                            {i18n.language === "ru" &&
                                                <p><span><b>*</b></span> Сроки указаны на создание <b>landing сайта</b>. В случае разработки более крупного проекта, затраченное время на каждом этапе увеличивается</p>
                                            }
                                            {i18n.language === "en" &&
                                                <p><span><b>*</b></span> The terms are specified for the creation of <b>landing site</b>. In case of development of a larger project, the time spent at each stage increases</p>
                                            }

                                        </TabPanel>

                                        <TabPanel>
                                            <Steps data={dataListTwo} />
                                        </TabPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default TabTwo
