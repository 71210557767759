import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useSnackbar } from 'notistack'

const Result = () => {
    return (
        <div className="success-message">
            <div className='success-message__block'>
                <div className='success-message__img' style={{ maxWidth: `260px` }}><img src="./images/contact/done.png" /></div>
                <div className='success-message__title'>Заявка отправлена!</div>
                <div className='success-message__subtitle'>Мы рассмотрим вашу заявку<br />и свяжемся с вами в ближайшее время!</div>
            </div >
        </div >
    )
}

const FormGudok = ({ title, subtitle }) => {

    // Смена языка
    const { t, i18n } = useTranslation();

    // Уведомления
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // Показ результата после отправки формы
    const [result, showresult] = useState(false);

    // Объект с данными с формы
    const [form, setForm] = useState({
        phone: ''
    })

    // Сбор данных с формы
    const DataCollection = (e) => {
        setForm(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    };

    // Функция имитации клика по объекту
    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    function simulateMouseClick(element) {
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
    }

    // Отправка данных на гудок
    const sendGudok = (e) => {
        e.preventDefault();

        const formGudok = document.querySelector('#cbw-optionsFlex');
        const ButtonGudok = document.querySelector('#cbw-buttonButton');

        if (formGudok == null) {
            simulateMouseClick(ButtonGudok);
            simulateMouseClick(ButtonGudok);
        }

        const PhoneGudok = document.querySelector('#cbw-formTel')
        const formButtonGudok = document.querySelector('#cbw-formButton');

        PhoneGudok.value = form.phone;

        simulateMouseClick(formButtonGudok);

        enqueueSnackbar('Заявка успешно отправлена', {
            autoHideDuration: 3000,
            variant: 'success',
        })

        document.querySelector('.form-gudok input[name="phone"]').value = '';

        showresult(true);

    }

    return (
        <>
            <div className='form-gudok'>

                <form className='contact-form-1 bg-color-blackest radius' onSubmit={sendGudok}>
                    <h3 style={{ textAlign: `center`, marginBottom: `8px` }}>{title}</h3>
                    <p style={{ textAlign: `center` }}>{subtitle}</p>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <InputMask
                                    required
                                    type="text"
                                    name="phone"
                                    mask="+7 (999) 999-9999"
                                    placeholder="+7 (___)___-____"
                                    onChange={e => DataCollection(e)}
                                >
                                </InputMask>
                            </div>
                        </div>
                    </div>

                    <div className="mt--10 mb--20">{t("contacts.form.textPolicy")} <a href="">{t("contacts.form.linkPolicy")}</a></div>

                    <div className="form-group">
                        <button className="btn-default btn-large">{t("contacts.form.button")}</button>
                    </div>

                    {/* Блок с выводом результата */}
                    {result ? <Result /> : null}

                </form>
            </div>
        </>
    )
}
export default FormGudok;
