import React, { useEffect } from 'react';

const ModalWindow = ({ children, active, closeCallback }) => {

    useEffect(() => {
        let html = document.querySelector('html');
        if (active) { html.style.overflow = 'hidden'; } else { html.style.overflow = 'auto'; }
    }, [active])

    return (
        <>
            {active &&
                <div className="modal-window">
                    <div className="modal-window__block">
                        <div className='modal-window__content'>{children}</div>
                        <div className='modal-window__close' onClick={closeCallback}></div>
                    </div>
                </div>
            }
        </>
    )
}
export default ModalWindow;
