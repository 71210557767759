import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

// Иконки
import { SiVk } from "react-icons/si";
import { ImTelegram, ImWhatsapp } from "react-icons/im";
import { AiFillHtml5 } from "react-icons/ai";
import { IoLogoCss3, IoLogoJavascript } from "react-icons/io";
import { SiPhp } from "react-icons/si";
import { BiLogoJquery, BiLogoBootstrap, BiFontSize } from "react-icons/bi";
import { FaLess, FaReact, FaGulp } from "react-icons/fa";
import { BsFiletypeScss, BsFiletypeJsx } from "react-icons/bs";

const teamData = [
    {
        image: 'logo.png',
        name: 'Денис Наумов',
        designation: 'Генеральный Директор',
        location: 'CO Miego, AD, USA',
        description: 'Yes, I am a product designer. I have a passion for product design.',
        socialNetwork: [
            {
                icon: <SiVk />,
                url: '#'
            },
            {
                icon: <ImTelegram />,
                url: '#'
            },
            {
                icon: <ImWhatsapp />,
                url: '#'
            },
        ],
        dataTechology: [
            {
                id: 1,
                name: "HTML",
                icon: <AiFillHtml5 />
            },
            {
                id: 2,
                name: "CSS",
                icon: <IoLogoCss3 />
            },
            {
                id: 3,
                name: "JavaScript",
                icon: <IoLogoJavascript />
            },
            {
                id: 4,
                name: "PHP",
                icon: <SiPhp />
            },
            {
                id: 5,
                name: "Jquery",
                icon: <BiLogoJquery />
            },
            {
                id: 6,
                name: "Bootstrap",
                icon: <BiLogoBootstrap />
            },
            {
                id: 7,
                name: "LESS",
                icon: <FaLess />
            },
            {
                id: 8,
                name: "SCSS",
                icon: <BsFiletypeScss />
            },
            {
                id: 9,
                name: "React",
                icon: <FaReact />
            },
            {
                id: 10,
                name: "JSX",
                icon: <BsFiletypeJsx />
            },
            {
                id: 11,
                name: "Gulp",
                icon: <FaGulp />
            },

        ]
    },
    {
        image: 'logo.png',
        name: 'Александр Савченко',
        designation: 'Senior Fullstack разработчик',
        location: 'Bangladesh',
        description: 'Yes, I am a product designer. I have a passion for product design.',
        socialNetwork: [
            {
                icon: <SiVk />,
                url: '#'
            },
            {
                icon: <ImTelegram />,
                url: '#'
            },
            {
                icon: <ImWhatsapp />,
                url: '#'
            },
        ],
        dataTechology: [
            {
                id: 1,
                name: "HTML",
                icon: <AiFillHtml5 />
            },
            {
                id: 2,
                name: "CSS",
                icon: <IoLogoCss3 />
            },
            {
                id: 3,
                name: "JavaScript",
                icon: <IoLogoJavascript />
            },
            {
                id: 4,
                name: "PHP",
                icon: <SiPhp />
            },
            {
                id: 5,
                name: "Jquery",
                icon: <BiLogoJquery />
            },
            {
                id: 6,
                name: "Bootstrap",
                icon: <BiLogoBootstrap />
            },
            {
                id: 7,
                name: "LESS",
                icon: <FaLess />
            },
            {
                id: 8,
                name: "SCSS",
                icon: <BsFiletypeScss />
            },
            {
                id: 9,
                name: "React",
                icon: <FaReact />
            },
            {
                id: 10,
                name: "JSX",
                icon: <BsFiletypeJsx />
            },
            {
                id: 11,
                name: "Gulp",
                icon: <FaGulp />
            },
        ]
    },
    {
        image: 'logo.png',
        name: 'Ангелина Бондарева',
        designation: 'Junior Fornt-end разработчик',
        location: 'Poland',
        description: 'Yes, I am a product designer. I have a passion for product design.',
        socialNetwork: [
            {
                icon: <SiVk />,
                url: '#'
            },
            {
                icon: <ImTelegram />,
                url: '#'
            },
            {
                icon: <ImWhatsapp />,
                url: '#'
            },
        ],
        dataTechology: [
            {
                id: 1,
                name: "HTML",
                icon: <AiFillHtml5 />
            },
            {
                id: 2,
                name: "CSS",
                icon: <IoLogoCss3 />
            },
            {
                id: 3,
                name: "JavaScript",
                icon: <IoLogoJavascript />
            },
            {
                id: 4,
                name: "PHP",
                icon: <SiPhp />
            },
            {
                id: 5,
                name: "Jquery",
                icon: <BiLogoJquery />
            },
            {
                id: 6,
                name: "Bootstrap",
                icon: <BiLogoBootstrap />
            },
            {
                id: 7,
                name: "LESS",
                icon: <FaLess />
            },
            {
                id: 8,
                name: "SCSS",
                icon: <BsFiletypeScss />
            },
            {
                id: 9,
                name: "React",
                icon: <FaReact />
            },
            {
                id: 10,
                name: "JSX",
                icon: <BsFiletypeJsx />
            },
            {
                id: 11,
                name: "Gulp",
                icon: <FaGulp />
            },
        ]
    },
]

const TeamTwo = ({ column, teamStyle }) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`rn-team ${teamStyle}`}>
                            <div className="inner bg-color-blackest radius rbt-border">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <div>
                                        <div>Навыки</div>
                                        <div>
                                            {data.dataTechology.map((el, i) =>
                                                <div>
                                                    <div>{el.icon}</div>
                                                    <div style={{ fontSize: '14px' }}>{el.name}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span> */}
                                    {/* <p className="description">{data.description}</p> */}

                                    {/* <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul> */}
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation >
                </div >
            ))}
        </div >
    )
}

export default TeamTwo;
