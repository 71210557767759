import React from 'react';
import { useTranslation } from 'react-i18next';

// Компоненты основные
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree.jsx';

const Requisites = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <SEO title="Реквизиты || FLAMINGO" />
            <main className="page-wrapper">

                {/* Шапка */}
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

                {/* Блок контактов */}
                <div className="requisites-area rn-section-gap" >
                    {i18n.language === "ru" &&
                        <div className="container">
                            <h2>Реквизиты</h2>
                            <div className="row row--30 mt--40">
                                <div className="col-lg-6 col-12 mb-lg-0  mb-5">
                                    <div className='requisites__block requisites_russia bg-color-blackest radius'>
                                        <h3>Россия</h3>
                                        <p><b>ИП</b> НАУМОВ Д.А.</p>
                                        <p><b>ИНН:</b> 614103375516</p>
                                        <p><b>ОГРНИП:</b> 319619600034368</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className='requisites__block requisites_kazakhstan bg-color-blackest radius'>
                                        <h3>Казахстан</h3>
                                        <p><b>ТОО</b> "Flamingo Expert (Фламинго Эксперт)"</p>
                                        <p><b>БИН</b> 230640013961</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {i18n.language === "en" &&
                        <div className="container">
                            <h2>Requisites</h2>
                            <div className="row row--30 mt--40">
                                <div className="col-lg-6 col-12 mb-lg-0  mb-5">
                                    <div className='requisites__block requisites_russia bg-color-blackest radius'>
                                        <h3>Russia</h3>
                                        <p><b>IE</b> Naumov D.A</p>
                                        <p><b>ITN:</b> 614103375516</p>
                                        <p><b>PSRNIE:</b> 319619600034368</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className='requisites__block requisites_kazakhstan bg-color-blackest radius'>
                                        <h3>Kazakhstan</h3>
                                        <p><b>LLC</b> "Flamingo Expert"</p>
                                        <p><b>BIN</b> 230640013961</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>


                {/* Футер */}
                <FooterThree />

            </main>
        </>
    )
}

export default Requisites;
