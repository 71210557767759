import React from 'react';
import { useTranslation } from 'react-i18next';

// Иконки
import { TbTilde } from "react-icons/tb";
import { FaModx, FaJoomla } from "react-icons/fa";
import { BsWordpress } from "react-icons/bs";



const dataCms = [
    {
        id: 1,
        name: "WordPress",
        icon: <BsWordpress />
    },
    {
        id: 2,
        name: "Joomla",
        icon: <FaJoomla />
    },
    {
        id: 3,
        name: "MODX",
        icon: <FaModx />
    },
    {
        id: 4,
        name: "Tilda",
        icon: <TbTilde />
    }
]

const CmsPortfolio = ({ data }) => {

    const { t, i18n } = useTranslation();

    return (
        <div className="portfolio-details__cms">
            <div className="portfolio-details__subtitle">{t("cases.caseDevelop.cms")}</div>
            <div className="row">
                {dataCms.map((tech, i) => (
                    data.map((el, index) => (
                        tech.id === el.id &&
                        <div key={i} className="col-12">
                            <div className="portfolio-details__cms-item">
                                <div>{tech.icon}</div>
                                <div>{tech.name}</div>
                            </div>
                        </div>
                    ))
                ))}
            </div>
        </div>
    )
}

export default CmsPortfolio;
