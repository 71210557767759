import React from 'react';
import { useTranslation } from 'react-i18next';

const OfferAdvantages = ({ }) => {

    const { t, i18n } = useTranslation();

    const data = [
        {
            icon: `${process.env.PUBLIC_URL + "/images/icons/medal.png"}`,
            title: t("offer.advantages.itemOne.title"),
            subtitle: t("offer.advantages.itemOne.subtitle")
        },
        {
            icon: `${process.env.PUBLIC_URL + "/images/icons/globe-earth.png"}`,
            title: t("offer.advantages.itemTwo.title"),
            subtitle: t("offer.advantages.itemTwo.subtitle")
        },
        {
            icon: `${process.env.PUBLIC_URL + "/images/icons/management.png"}`,
            title: t("offer.advantages.itemThree.title"),
            subtitle: t("offer.advantages.itemThree.subtitle")
        }
    ]

    return (
        <div className="row">
            {data.map((el, i) => (
                <div key={i} className="col-12 col-sm-4">
                    <div className='offer-item'>
                        <div>
                            <div className='offer-item__icon'><img src={el.icon} alt="" /></div>
                            <div className='offer-item__title'>{el.title}</div>
                            <div className='offer-item__subtitle'>{el.subtitle}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default OfferAdvantages;
