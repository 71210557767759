import React from 'react';


const PortfolioMarketing = ({ data }) => {
    return (
        <div>Здесь будет кейсы по маркетингу</div>
    )
}

export default PortfolioMarketing;
