import React from 'react';
import CopyrightTwo from "./CopyrightTwo.jsx";
import ScrollTop from "./ScrollTop";
import MessengersPanel from '../../elements/contact/MessengersPanel.jsx';


const FooterThree = () => {
    return (
        <>
            <div className="footer-style-2 rn-section-gapTop">
                <CopyrightTwo />
            </div>
            <ScrollTop />
            <MessengersPanel />
        </>
    )
}
export default FooterThree;